.icon-arrow-next {
  width: 0.69em;
  height: 1em;
  fill: #252525;
}
.icon-arrow-prev {
  width: 0.69em;
  height: 1em;
  fill: #252525;
}
.icon-close {
  width: 1.09em;
  height: 1em;
  fill: #EB5757;
}
.icon-facebook {
  width: 1.03em;
  height: 1em;
  fill: #fff;
}
.icon-ok {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-planet {
  width: 1.42em;
  height: 1em;
  fill: #AD92DE;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-twitter {
  width: 1.03em;
  height: 1em;
  fill: #fff;
}
.icon-vk {
  width: 1.03em;
  height: 1em;
  fill: #fff;
}
